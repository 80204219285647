import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { AffiliatePartnersContent } from '@/pages/affiliate-partners';
import Section from '@/components/Section';
import {
  Body1,
  Body4Bold,
  H1,
  H2,
  H3,
  P,
  SecondaryP,
  StrongInitial,
  Subtitle1,
  Subtitle5,
} from '@/components/Typography';
import FirstVpBg from '../assets/img/affiliateBg.png';
import YellowCoin from '../assets/icons/yellowCoin.svg';
import Vp1Image from '../assets/img/affiliate1VpImg.png';
import Vp2Image from '../assets/img/affiliatesVp2.png';
import BannerBg from '../assets/img/affiliatesBannerBg.png';
import ButtonDefault from '@/components/ButtonDefault';
import FirstViewportMirrored from '@/components/FirstViewportMirrored';
import GreenBullet from '../assets/icons/greenBullet.svg';
import Banner from '@/components/Banner';

const FirstVpWrapper = styled.div`
  background-image: ${(props) => `url(${props.resource})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
`;

const ThirdViewport = styled(Section)``;

const StyledSection = styled(Section)`
  padding-bottom: 0;
`;

const FourthViewport = styled(Section)``;

const TopTitle = styled(H1)`
  text-align: center;
  font-size: 54px;
  @media (max-width: 1200px) {
    text-align: left;
    font-size: 40px;
  }
`;

const BannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const CoinBanner = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 53px;
  border: 1px solid #dbe3eb;
  background: #fff;
  padding: 7px;
`;

const StyledYellowCoin = styled(YellowCoin)`
  margin-right: 0.5rem;
`;

const StyledVp1Img = styled.img`
  margin-top: 5rem;
  width: 100%;
  border-radius: 30px;
`;

const SubSection = styled(Section)`
  margin-top: 0 !important;
  display: flex;
  align-items: center;
  margin-top: 6rem;
  background: linear-gradient(
    180deg,
    #e3f3f5 -1.12%,
    rgba(56, 182, 255, 0.1) 52.74%,
    rgba(44, 209, 158, 0.34) 104.36%
  );
`;

const SubLeft = styled.div`
  flex: 1 1 25%;
  padding-right: 5rem;
  @media (max-width: 1200px) {
    text-align: left;
    margin-bottom: 1rem;
    padding-right: 0;
  }
`;

const GrayLabel = styled(Body4Bold)`
  text-transform: uppercase;
  color: #8181a5;
  margin-bottom: 1rem;
`;

const VpHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0;
`;

const StyledSecondaryP = styled(SecondaryP)`
  margin-top: 1.5rem;
  text-align: center;
`;

const Steps = styled.div`
  display: flex;
  padding-left: 2rem;
  &:last-of-type {
    span {
      display: none;
    }
  }
  @media (max-width: 1200px) {
    padding-left: 0;
  }
`;

const CircleAndDash = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
`;

const Line = styled.span`
  border: 1px solid #c2cedb;
  height: 100%;
`;

const Vp5Text = styled.div``;

const Step = styled(StrongInitial)`
  margin-bottom: 1rem;
`;

const DescriptionDashed = styled(P)`
  margin-top: -0.4rem;
  padding-bottom: 2rem;
`;

const StyledGreenBullet = styled(GreenBullet)``;

const BulletWrapper = styled.div`
  height: 19px;
`;

const StyledH2 = styled(H2)`
  text-align: center;
`;

const StyledSubtitle5 = styled(Subtitle5)`
  text-align: center;
  font-weight: 500;
  margin-top: 3rem;
`;

const StyledButtonDefault = styled(ButtonDefault)`
  margin-top: 3rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  color: #027aff;
  text-decoration: none;
`;

const StyledSubtitle1 = styled(Subtitle1)`
  text-align: center;
`;

interface AffiliatePartnersProps {
  content: AffiliatePartnersContent;
}

const AffiliatePartnersContainer: React.FunctionComponent<
  AffiliatePartnersProps
> = ({ content: { viewport1, viewport2, viewport3, viewport4 } }) => (
  <>
    <FirstVpWrapper resource={FirstVpBg}>
      <StyledSection>
        <TopTitle>{viewport1.topTitle}</TopTitle>
        <BannerWrapper>
          <CoinBanner>
            <StyledYellowCoin />
            <Body1>
              {viewport1.topDescription1}
              &nbsp;
              <span className="accent-text-black-bold">
                {viewport1.topDescription2}
              </span>
              &nbsp;
              {viewport1.topDescription3}
            </Body1>
          </CoinBanner>
        </BannerWrapper>
        <StyledVp1Img src={Vp1Image} alt="img" />
      </StyledSection>
      <SubSection>
        <SubLeft>
          <GrayLabel>{viewport1.label}</GrayLabel>
          <H3>
            {viewport1.description1}&nbsp;
            <span className="accent-text-red ">{viewport1.description2}</span>
            &nbsp;
            {viewport1.description3}
          </H3>
        </SubLeft>

        <ButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </ButtonDefault>
      </SubSection>
    </FirstVpWrapper>
    <div>
      <VpHeader>
        <GrayLabel>{viewport2.label}</GrayLabel>
        <StyledSubtitle1>{viewport2.title}</StyledSubtitle1>
        <StyledSecondaryP>{viewport2.description}</StyledSecondaryP>
      </VpHeader>
      <FirstViewportMirrored withRightPadding img={Vp2Image} imgWidth="50%">
        {viewport2.howDoesItWorkCards.map((el) => (
          <Steps>
            <CircleAndDash>
              <BulletWrapper>
                <StyledGreenBullet />
              </BulletWrapper>
              <Line />
            </CircleAndDash>
            <Vp5Text>
              <Step>{el.label}</Step>
              <DescriptionDashed>{el.description}</DescriptionDashed>
            </Vp5Text>
          </Steps>
        ))}
      </FirstViewportMirrored>
    </div>
    <ThirdViewport>
      <Banner
        bgSrc={BannerBg}
        bannerCaption={viewport3.bannerCaption}
        label={viewport3.bannerLabel}
        buttonLabel={viewport3.button.label}
        buttonUrl={viewport3.button.url}
        buttonColor="#2CD19E"
      />
    </ThirdViewport>
    <FourthViewport>
      <StyledH2>
        {viewport4.title1}&nbsp;
        <span className="accent-text-red">{viewport4.title2}</span>&nbsp;
        {viewport4.title3}&nbsp;
        <span className="accent-text-red">{viewport4.title4}</span>&nbsp;
        {viewport4.title5}
      </StyledH2>
      <StyledSubtitle5>
        {viewport4.description1}
        &nbsp;
        <StyledLink to={viewport4.descriptionUrl}>
          {viewport4.description2}
        </StyledLink>
      </StyledSubtitle5>
      <ButtonWrapper>
        <StyledButtonDefault to={viewport4.button.url}>
          {viewport4.button.label}
        </StyledButtonDefault>
      </ButtonWrapper>
    </FourthViewport>
  </>
);

export default AffiliatePartnersContainer;
