import React from 'react';
import Main from '../containers/Layout';
import affiliatePartnersContent from '../../content/pages/affiliate-partners.yml';
import AffiliatePartnersContainer from '@/containers/Affiliate-partners';

export interface AffiliatePartnersContent {
  viewport1: {
    topTitle: string;
    topDescription1: string;
    topDescription2: string;
    topDescription3: string;
    label: string;
    description1: string;
    description2: string;
    description3: string;
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    label: string;
    title: string;
    description: string;
    howDoesItWorkCards: {
      label: string;
      description: string;
    }[];
  };
  viewport3: {
    bannerCaption: string;
    bannerLabel: string;
    button: {
      label: string;
      url: string;
    };
  };
  viewport4: {
    title1: string;
    title2: string;
    title3: string;
    title4: string;
    title5: string;
    description1: string;
    description2: string;
    descriptionUrl: string;
    button: {
      label: string;
      url: string;
    };
  };
}
const AffiliatePartners: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3, viewport4 } =
    affiliatePartnersContent as unknown as AffiliatePartnersContent;
  return (
    <Main>
      <AffiliatePartnersContainer
        content={{ viewport1, viewport2, viewport3, viewport4 }}
      />
    </Main>
  );
};

export default AffiliatePartners;
